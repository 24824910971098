import Button from "@/components/Button"
import RadioGroup from "@/components/forms/RadioGroup"
import Select from "@/components/forms/Select"
import Slider from "@/components/forms/Slider"
import TextInput from "@/components/forms/TextInput"
import InlineNotification from "@/components/InlineNotification"
import SettingsSidebar from "@/components/interface/SettingsSidebar"
import Layout from "@/components/layouts/DefaultLayout"
import LoadingSpinner from "@/components/LoadingSpinner"
import Settings, { SettingsField, SettingsFooter } from "@/components/Settings"
import { DEVICE_CONNECTED, DEVICE_DISCONNECTED, DEVICE_SETTINGS_URL, NO_DEVICES } from "@/constants"
import { useSerialDevice } from "@/contexts/serial-device"
import { DEFAULT_SETTINGS } from "@/settings"
import { buildSettingsUpdate, toSettingsUpdate } from "@/utils/device"
import { useEffect, useState } from "react"
import { Outlet as SubPage, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const DeviceSettingsPage = () => {
  const device = useSerialDevice()
  const navigate = useNavigate()
  const location = useLocation()

  if (device.status === NO_DEVICES) {
    navigate("/")
    toast.warning("Device must be connected")
  }

  return (
    <Layout>
      <SettingsSidebar />
      <div className="relative w-full flex-1 overflow-y-auto px-20 py-14">
        {location.pathname === DEVICE_SETTINGS_URL ? <MainPage /> : <SubPage />}
      </div>
    </Layout>
  )
}

const SETTINGS_CONFIG = [
  {
    label: "General Settings",
    key: "UI",
    fields: [
      {
        fieldClassName: "pt-3",
        key: "Screen Timeout",
        instructions: [
          "Default: 5 seconds. Range: 2 to 120 seconds. Warning: Choosing 'Never' may result in burn-in.",
        ],
        component: Select,
      },
      {
        key: "Screen Contrast",
        component: Select,
      },
      {
        key: "LED Mode",
        component: Select,
        instructions: [
          "ON: LEDs remain on while Element IV is on",
          "WITH SCREEN: LEDs turn off while the screen is off.",
          "5 MIN STANDBY: LEDs turn off after 5 minutes of USB inactivity.",
        ],
      },
      {
        key: "Knob Speed",
        label: "Volume Knob Speed",
        component: RadioGroup,
        componentClassName: "mt-3",
        instructions: [
          "Choose HALF to make the volume knob feel more gradual, or choose FULL for quickest volume adjustment.",
        ],
      },
      {
        key: "Volume Steps",
        label: "Volume Knob Steps",
        component: RadioGroup,
        componentClassName: "mt-3",
        instructions: [
          'Defines how much the listening volume increases or decreases per "step" as the knob is turned.',
        ],
      },
      {
        key: "Auto Gain Speed",
        component: Select,
        instructions: [
          "Automatic gain occurs at the 0dB threshold: Aggressive transitions between low/high gain rapidly, whereas the Default setting displays a progress bar and requires more knob turning to enact the gain transition.",
        ],
      },
      {
        key: "Knob Button",
        label: "Knob Button Behavior",
        component: Select,
      },
    ],
  },
  {
    label: "USB Settings",
    key: "General",
    fields: [
      {
        key: "UAC Mode",
        label: "USB Audio Class",
        instructions: [
          "UAC2 is recommended for macOS, Linux, and Windows 10/11.",
          "Chose UAC1 for PS4/PS5/Nintendo Switch. NOTE: This app is not supported in UAC1 mode. To reconnect here, long press the knob button and choose USB Audio Class --> UAC2.",
        ],
        component: Select,
      },
      {
        key: "Inactive Mute",
        label: "Mute Inactive USB",
        instructions: ['Enable to eliminate "alien sounds" in Linux/Android.'],
        component: RadioGroup,
      },
    ],
  },
  {
    label: "DAC Configuration",
    key: "DAC",
    fields: [
      {
        key: "Max Sample Rate",
        fieldClassName: "pt-8",
        instructions: [
          "All 10-band EQ functionality is available up to 32/192kHz. Please note that Peaking filters are ignored at 352.4-384kHz; bass and treble controls remain available (LP and HP filters).",
        ],
        component: Select,
      },
      {
        key: "Second Harmonic Compensation",
        label: "2nd Harmonic Compensation",
        fieldClassName: "pt-8",
        componentClassName: "mt-3 max-w-4xl",
        component: "SliderWithInput",
        step: 1,
        instructions: [
          "Element 4 is tuned for optimal performance: altering this value in either direction will increase the 2nd harmonic for a slightly harsher sound.",
        ],
      },
      {
        key: "Third Harmonic Compensation",
        label: "3rd Harmonic Compensation",
        fieldClassName: "pt-8",
        componentClassName: "mt-3 max-w-4xl",
        component: "SliderWithInput",
        step: 1,
        instructions: [
          "Element 4 is tuned for optimal performance: altering this value in either direction will increase the 3rd harmonic for a slightly warmer sound.",
        ],
      },
      {
        key: "DPLL Bandwidth",
        fieldClassName: "pt-8",
        componentClassName: "mt-3 max-w-4xl",
        component: "SliderWithInput",
        step: 1,
        instructions: [
          "This setting impacts jitter rejection. The default value is suitable for most PCs and modern TVs. Try increasing DPLL Bandwidth if you encounter stuttering via TOSLINK.",
        ],
      },
      {
        key: "SPDIF Deemphasis",
        component: RadioGroup,
      },
      {
        key: "DAC Filter",
        component: Select,
        instructions: [
          "Interpolation filters of the ESS DAC. Most listeners find it difficult to perceive any noticeable difference between interpolation filters; we recommend using the default setting (Fast Rolloff).",
          "FAST ROLLOFF produces a steep high-frequency cut near the Nyquist frequency to minimize aliasing. Audible Impact: This yields a precise, detailed sound with sharper high-frequency extension.",
          "SLOW ROLLOFF provides a gradual attenuation near the Nyquist limit, preserving a smoother high-frequency response. Audible Impact: This results in a warm, natural sound with less high-frequency detail.",
          "MIN PHASE avoids pre-ringing by focusing all impulse response energy post-initial sound, maintaining phase accuracy. Audible Impact: This provides a natural, lifelike sound with coherent timing, making vocals and acoustic instruments feel more present.",
        ],
      },
    ],
  },
]

/**
 * Render a field from a configuration. This allows us to more easily render
 * all of the settings fields by simply providing a configuration object that
 * looks like:
 *
 * const FIELDS = [{
 *   key: "Field A",
 *  component: Select
 * }, {
 *   key: "Field A",
 *  component: Select
 * }]
 *
 * and using:
 *
 * {
 *   FIELDS.map((field) =>
 *     renderSettingsField({ settings, section: "General", field, onChange })
 *   )
 * }
 *
 * @param param0
 * @returns
 */
const renderSettingsField = ({ settings, section, field, onChange }) => {
  const componentProps = {
    className: field.componentClassName || "",
    "aria-label": field.label || field.key,
  }
  if (field.component === Select || field.component === RadioGroup) {
    componentProps["selected"] = settings["Configuration"][section][field.key]["Current"]
    componentProps["items"] = settings["Configuration"][section][field.key]["Elements"].map(
      (settingValue) => {
        return {
          label: settingValue,
          value: settingValue,
        }
      }
    )
    if (field.component === RadioGroup) {
      componentProps["onValueChange"] = (value) => {
        onChange(["Configuration", section, field.key], value)
      }
    }
    if (field.component === Select) {
      componentProps["onSelectionChange"] = (values) => {
        values.forEach((value) => {
          onChange(["Configuration", section, field.key], value)
        })
      }
    }
  }
  if (field.component === Slider) {
    componentProps["onDebouncedChange"] = (value) => {
      onChange(["Configuration", section, field.key], value)
    }
    componentProps["value"] = settings["Configuration"][section][field.key]["Current"]
    componentProps["minValue"] = settings["Configuration"][section][field.key]["Min"]
    componentProps["maxValue"] = settings["Configuration"][section][field.key]["Max"]
    componentProps["step"] = field.step
  }
  if (field.component === TextInput) {
    componentProps["value"] = settings["Configuration"][section][field.key]["Current"]
    componentProps["onChange"] = (e) => {
      const newValue = e.target.value
      // Only update if the new value is a valid number within the allowed range
      if (newValue !== "" && !isNaN(Number(newValue))) {
        const numValue = Number(newValue)
        const min = settings["Configuration"][section][field.key]["Min"]
        const max = settings["Configuration"][section][field.key]["Max"]
        if (numValue >= min && numValue <= max) {
          onChange(["Configuration", section, field.key], numValue)
        }
      }
    }
    componentProps["placeholder"] = `Enter ${field.label || field.key}`
    componentProps["type"] = "number"
    componentProps["min"] = settings["Configuration"][section][field.key]["Min"]
    componentProps["max"] = settings["Configuration"][section][field.key]["Max"]
  }
  if (field.component === "SliderWithInput") {
    componentProps["value"] = settings["Configuration"][section][field.key]["Current"]
    componentProps["onChange"] = (value) => {
      onChange(["Configuration", section, field.key], value)
    }
    componentProps["min"] = settings["Configuration"][section][field.key]["Min"]
    componentProps["max"] = settings["Configuration"][section][field.key]["Max"]
    componentProps["step"] = field.step
  }
  return (
    <SettingsField
      className={field.fieldClassName || ""}
      key={field.key}
      label={field.label || field.key}
      instructions={field.instructions}
    >
      {field.component === "SliderWithInput" ? (
        <SliderWithInput {...componentProps} />
      ) : (
        <field.component {...componentProps} />
      )}
    </SettingsField>
  )
}

const SliderWithInput = ({ value, onChange, min, max, step, className }) => {
  return (
    <div className={`flex items-center ${className}`}>
      <Slider
        value={value}
        onDebouncedChange={onChange}
        minValue={min}
        maxValue={max}
        step={step}
        className="mr-4 flex-grow"
      />
      <div className="whitespace-nowrap text-sm font-medium">Value: {value}</div>
    </div>
  )
}

const MainPage = () => {
  const device = useSerialDevice()
  const [settings, setSettings] = useState()

  /**
   * When a setting changes, push to device
   *
   * @param keys
   * @param value
   */
  const onChange = async (keys, value) => {
    const settingUpdates = buildSettingsUpdate({}, keys, value)
    try {
      await device.updateSettings(settingUpdates)
      // Update local state to reflect the change
      setSettings(prevSettings => {
        const newSettings = structuredClone(prevSettings)
        // Navigate to the correct nested location using the keys array
        let current = newSettings
        for (let i = 0; i < keys.length - 1; i++) {
          current = current[keys[i]]
        }
        current[keys[keys.length - 1]]["Current"] = value
        return newSettings
      })
      toast.success("Setting updated")
    } catch (error) {
      toast.error("Error updating setting")
    }
  }

  /**
   * When the factory reset button is clicked, convert the default settings
   * into a format that can be sent to the device.
   *
   */
  const onFactoryResetClicked = async () => {
    const confirmation = await window.confirm("Are you sure you want to reset factory settings")
    if (confirmation) {
      const factoryResetSettings = toSettingsUpdate(DEFAULT_SETTINGS)
      try {
        await device.updateSettings(factoryResetSettings)
        toast.success("Device reset to factory settings")
      } catch (error) {
        toast.error("Error resetting device")
      }
    }
  }

  /**
   * Load settings from the device
   *
   */
  useEffect(() => {
    if (device.status === DEVICE_CONNECTED) {
      ;(async () => {
        const response = await device.getSettings()
        setSettings(response)
      })()
    }
  }, [device.status])

  if (device.status === DEVICE_DISCONNECTED) {
    return (
      <InlineNotification level="error" showIcon={false}>
        <>
          Your device has been disconnected. Please reconnect the device in order to update the
          settings.
        </>
      </InlineNotification>
    )
  }

  if (!settings) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <span className="flex items-center gap-2">
          <LoadingSpinner size="small" />{" "}
          <span className="text-xs text-gray-500">LOADING DEVICE SETTINGS</span>
        </span>
      </div>
    )
  }

  return (
    <>
      {SETTINGS_CONFIG.map((config, i) => {
        return (
          <Settings className="mb-12" key={`section-${i}`} title={config.label}>
            {config.fields.map((field, i) => {
              return renderSettingsField({
                settings: settings,
                section: config.key,
                field,
                onChange,
              })
            })}
          </Settings>
        )
      })}
      <SettingsFooter>
        <Button onClick={onFactoryResetClicked}>Reset to Factory</Button>
      </SettingsFooter>
    </>
  )
}

export default DeviceSettingsPage
