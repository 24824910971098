import Button from "@/components/Button"
import ModePanel from "@/components/interface/ModePanel"
import WarningCallout from "@/components/WarningCallout"
import { DEVICE_CONNECTED, DEVICE_DISCONNECTED, NO_DEVICES, DEVICE_UNSUPPORTED } from "@/constants"
import { useAuth } from "@/contexts/auth"
import { useSerialDevice } from "@/contexts/serial-device"
import { ReactComponent as BrowserWarningIcon } from "@/icons/browser-warning.svg"
import { ReactComponent as ElementIcon } from "@/icons/element-icon.svg"

const EqualiserSidebar = ({
  mode,
  presets,
  activePreset,
  onModeChanged,
  onPresetSeleceted,
  onDisableEqClicked,
  onExportAllClicked,
  onImportPresetClicked,
}) => {
  const { user } = useAuth()
  const { status } = useSerialDevice()

  return (
    <aside className="flex h-full w-full flex-col justify-around border-l border-mine-shaft font-bold">
      <div className="flex flex-1 flex-col justify-start overflow-y-auto py-10 pl-10 pr-6">
        {status === DEVICE_UNSUPPORTED && <UnsupportedWarning />}
        {[NO_DEVICES, DEVICE_DISCONNECTED].includes(status) && <NoDevicesWarning />}
        {status === DEVICE_CONNECTED && (
          <ModePanel
            mode={mode}
            presets={presets}
            activePreset={activePreset}
            onModeChanged={onModeChanged}
            onPresetSeleceted={onPresetSeleceted}
            onDisableEqClicked={onDisableEqClicked}
          />
        )}
      </div>
      <div className="flex flex-shrink-0 gap-x-4 border-t border-mine-shaft pb-4 pl-10 pt-5">
        <Button theme="secondary" onPress={onImportPresetClicked}>
          Import Preset
        </Button>
        {user && (
          <Button theme="secondary" disabled={!user} onClick={onExportAllClicked}>
            Export Presets
          </Button>
        )}
      </div>
    </aside>
  )
}

const UnsupportedWarning = () => {
  return (
    <WarningCallout
      icon={<BrowserWarningIcon className="h-24" />}
      content={
        <>
          <p>
            Sorry, your current browser is not supported by JDS Labs Core. Supported browsers
            include:
          </p>
          <ul className="list-disc pl-5">
            <li>Google Chrome</li>
            <li>Microsoft Edge</li>
            <li>Opera</li>
            <li>Brave</li>
            <li>Firefox*</li>
          </ul>
          <p>
            *Firefox requires the{" "}
            <a
              href="https://addons.mozilla.org/en-US/firefox/addon/webserial-for-firefox/"
              className="text-red"
              target="_blank"
            >
              WebSerial for Firefox
            </a>{" "}
            extension.
          </p>
          <p>Safari and Chrome for Android do not support WebSerial.</p>
        </>
      }
    ></WarningCallout>
  )
}

const NoDevicesWarning = () => {
  const { request } = useSerialDevice()
  return (
    <WarningCallout
      heading={
        <>
          <ElementIcon className="h-4 w-auto text-white" />
          <h2>Connect your Element 4</h2>
        </>
      }
      content={
        <>
          <p>
            To connect Element 4, first make sure the device is plugged in to your computer via USB
            and powered on.
          </p>
          <p>
            Once complete, you are ready to connect. You will be prompted by your browser to
            establish a connection with Element 4.
          </p>
          <p>
            <Button
              type="secondary"
              size="small"
              outline
              onClick={() => {
                request()
              }}
            >
              Connect to Device
            </Button>
          </p>
        </>
      }
    ></WarningCallout>
  )
}

export default EqualiserSidebar
