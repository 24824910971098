import { axios, useAuth } from "@/contexts/auth"
import { useEffect, useState } from "react"
import { DEFAULT_SETTINGS } from "@/settings"

export type Preset = {
  id: number
  name: string
  mode: string
  is_favourite: boolean
  config: object
  created_at: Date
  updated_at: Date
}

const filterOrder = [
  "Lowshelf",
  "Peaking 1",
  "Peaking 2",
  "Peaking 3",
  "Peaking 4",
  "Peaking 5",
  "Peaking 6",
  "Peaking 7",
  "Peaking 8",
  "Highshelf",
]

export function usePresets() {
  const [presets, setPresets] = useState<Preset[]>()
  const [activePreset, setActivePreset] = useState<Preset | null>(null)
  const { isAuthenticated } = useAuth()

  const sortFunction = (a, b) => {
    if (a.is_favourite !== b.is_favourite) {
      return a.is_favourite === true ? -1 : 1
    }
    return new Date(b.created_at) - new Date(a.created_at)
  }

  const retrievePresets = async () => {
    if (isAuthenticated()) {
      const response = await axios.get("/api/equalizer/presets/")
      if (response.status === 200) {
        setPresets((prevPresets) => {
          const newPresets = response.data
          newPresets.sort(sortFunction)
          newPresets.forEach((preset) => {
            // If any preset is missing the Preamp settings, add them
            if (typeof preset.config["Preamp"] === "undefined") {
              preset.config["Preamp"] = DEFAULT_SETTINGS["Configuration"]["DSP"]["RCA"]["Preamp"]
            }
          })
          return newPresets
        })
      }
    }
  }

  const deletePreset = async (preset) => {
    if (isAuthenticated()) {
      const response = await axios.delete(`/api/equalizer/presets/${preset.id}/delete/`)
      if (response.status === 204) {
        if (activePreset && preset.id === activePreset?.id) {
          setActivePreset(null)
        }
        setPresets((prevPresets) => {
          const newPresets = prevPresets.filter((prevPreset) => prevPreset.id !== preset.id)
          newPresets.sort(sortFunction)
          return newPresets
        })
        return true
      }
    } else {
      console.warn("Cannot delete preset: user not authenticated")
      return false
    }
  }

  const ensureCorrectFilterOrder = (config) => {
    const orderedConfig = { Preamp: config.Preamp }
    filterOrder.forEach((filterType) => {
      if (config[filterType]) {
        orderedConfig[filterType] = config[filterType]
      } else {
        console.warn(`Missing filter in preset: ${filterType}`)
        orderedConfig[filterType] = {
          Gain: { Min: -30, Max: 30, Current: 0 },
          Frequency: { Min: 20, Max: 20000, Current: 1000 },
          Q: { Min: 0.1, Max: 100, Current: 0.707 },
        }
      }
    })
    return orderedConfig
  }

  const createPreset = async ({
    name,
    mode,
    config,
  }: {
    name: string
    mode: string
    config: object
  }): Promise<Preset | false> => {
    if (isAuthenticated()) {
      const orderedConfig = ensureCorrectFilterOrder(config)
      const { data: createdPreset } = await axios.post(`/api/equalizer/presets/`, {
        name,
        mode,
        config: orderedConfig,
      })
      setActivePreset(createdPreset)
      setPresets((prevPresets) => {
        const newPresets = prevPresets.map((p) => p)
        newPresets.push(createdPreset)
        newPresets.sort(sortFunction)
        return newPresets
      })
      return createdPreset
    } else {
      console.warn("Cannot create preset: user not authenticated")
    }
    return false
  }

  const updateActivePreset = async (preset): Promise<Preset | false> => {
    if (isAuthenticated()) {
      const orderedConfig = ensureCorrectFilterOrder(preset.config)
      const { data: updatedPreset } = await axios.patch(
        `/api/equalizer/presets/${preset.id}/update/`,
        { ...preset, config: orderedConfig }
      )
      setActivePreset(updatedPreset)
      setPresets((prevPresets) => {
        const newPresets = prevPresets.map((oldPreset) =>
          oldPreset.id === updatedPreset.id ? updatedPreset : oldPreset
        )
        newPresets.sort(sortFunction)
        return newPresets
      })
      return updatedPreset
    } else {
      console.warn("Cannot update preset: user not authenticated")
    }
    return false
  }

  //   When a user's authentication status changes, potentially fetch presets
  useEffect(() => {
    retrievePresets()
  }, [isAuthenticated])

  return {
    presets,
    activePreset,
    setActivePreset: (preset) => {
      if (preset) {
        const orderedConfig = ensureCorrectFilterOrder(preset.config)
        setActivePreset({ ...preset, config: orderedConfig })
      } else {
        setActivePreset(null)
      }
    },
    deletePreset,
    createPreset,
    updateActivePreset,
  }
}
