import EqualizerCanvas from "@/components/EqualizerCanvas"
import EqualizerTable from "@/components/EqualizerTable"
import EqualiserSidebar from "@/components/interface/EqualiserSidebar"
import PresetHeader from "@/components/interface/PresetHeader"
import EqualizerLayout from "@/components/layouts/EqualizerLayout"
import ConfirmationModal from "@/components/modals/ConfirmationModal"
import ImportPresetModal from "@/components/modals/ImportPresetModal"
import NewPresetModal from "@/components/modals/NewPresetModal"
import PreampGainSettings from "@/components/PreampGainSettings"
import { Tabs, Tab } from "@/components/Tabs"
import { ReactComponent as InfoIcon } from "@/icons/info-icon.svg"
import { DEVICE_CONNECTED, MODE_HEADPHONES } from "@/constants"
import { useSerialDevice } from "@/contexts/serial-device"
import { Preset, usePresets } from "@/hooks/presets"
import { DEFAULT_SETTINGS } from "@/settings"
import { applySettingsUpdate, buildSettingsUpdate, toSettingsUpdate } from "@/utils/device"
import {
  eqSettingsToText,
  eqSettingsToURLParam,
  reorderEqSettings,
  textToEqSettings,
  urlParamToEqSettings,
  validateEqSettings,
} from "@/utils/equaliser"
import { downloadBase64, downloadBlob } from "@/utils/file"
import { useDisclosure } from "@nextui-org/react"
import JSZip from "jszip"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import slugify from "slugify"

const EqualizerPage = () => {
  const location = useLocation()
  const device = useSerialDevice()
  const { presets, activePreset, setActivePreset, deletePreset, createPreset, updateActivePreset } =
    usePresets()

  const sharedEqSettings = useRef(null)
  const deviceReady = useRef(false)
  const [mode, setMode] = useState(MODE_HEADPHONES)
  const [dspSettings, setDspSettings] = useState(DEFAULT_SETTINGS["Configuration"]["DSP"])
  const [deviceSettings, setDeviceSettings] = useState(null)
  const [activePresetOutdated, setActivePresetOutdated] = useState(false)
  const [selectedTab, setSelectedTab] = useState("tabularEQ")
  const [eqChanged, setEqChanged] = useState(false)
  const [autoSave, setAutoSave] = useState(true)

  const {
    isOpen: newPresetModalOpen,
    onClose: closeNewPresetModal,
    onOpen: openNewPresetModal,
  } = useDisclosure()

  const {
    isOpen: importPresetModalOpen,
    onOpen: openImportPresetModal,
    onOpenChange: onImportPresetModalChange,
  } = useDisclosure()

  const {
    isOpen: shareModalOpen,
    onClose: closeShareModal,
    onOpen: openShareModal,
  } = useDisclosure()

  const applyEqSettings = async (newEqSettings) => {
    setDspSettings((prevDspSettings) => {
      prevDspSettings[mode] = structuredClone(reorderEqSettings(newEqSettings))
      return structuredClone(prevDspSettings)
    })
  }

  /**
   * When the EQ settings are changed either via the interactive canvas or the table.
   *
   * @param {*} updates
   */
  const onEqChange = async (updateArray) => {
    setEqChanged(true)
    const settingUpdates = updateArray.reduce((obj, [band, domain, value]) => {
      return buildSettingsUpdate(obj, [band, domain, "Current"], value)
    }, {})
    const newEqSettings = applySettingsUpdate(dspSettings[mode], settingUpdates)
    await applyEqSettings(newEqSettings)
  }

  /**
   * When the user clicks "Save to Device" button after a change, commit the settings
   */
  const onEqSave = async () => {
    setEqChanged(false)
    try {
      const response = await device.saveSettings()
      if (response) {
        toast.success("Device saved")
      }
    } catch (error) {
      console.error(error)
      toast.error("Error updating device")
    }
  }

  /**
   * When a preset is selected, load the preset's eq settings
   *
   * @param preset
   */
  const onPresetSelected = async (preset) => {
    setActivePreset(preset)
    setEqChanged(false)
    await applyEqSettings(preset.config)
  }

  /**
   * When "disable EQ" is clicked, reset the eq settings
   *
   */
  const onDisableEqClicked = async () => {
    setActivePreset(null)
    setEqChanged(false)
    const defaultEqSettings = DEFAULT_SETTINGS["Configuration"]["DSP"][mode]
    await applyEqSettings(defaultEqSettings)
  }

  /**
   * When the share dialog "confirm" button is clicked, apply the shared EQ settings
   */
  const onConfirmShareClicked = async () => {
    setActivePreset(null)
    await applyEqSettings(sharedEqSettings.current)
  }

  /**
   * When a new preset is imported by dropping on the overlay, load it.
   *
   */
  const onImportPresetConfirmed = async (file) => {
    setActivePreset(null)
    setEqChanged(false)
    const importedEqSettings = textToEqSettings(file.content)
    await applyEqSettings(importedEqSettings)
  }

  /**
   * When the "save" button is clicked, update the current preset
   *
   * NOTE: This should only be callable when the EQ values have changed while
   * a preset has already been selected
   */
  const onSavePresetClicked = async (preset) => {
    preset.config = dspSettings[mode]
    try {
      const updatedPreset = await updateActivePreset(preset)
      if (!updatedPreset) {
        throw new Error("Error updating preset")
      } else {
        toast.success("Updated preset")
      }
    } catch (error) {
      console.error(error)
      toast.error("Error updating preset")
    }
  }

  /**
   * When a "favourite" star is clicked, toggle the preset's favourite status
   *
   * @param preset
   */
  const onFavouritePresetClicked = async (preset: Preset) => {
    preset.is_favourite = !preset.is_favourite
    try {
      const updatedPreset = await updateActivePreset(preset)
      if (!updatedPreset) {
        throw new Error("Error favouriting preset")
      }
    } catch (error) {
      console.error(error)
      toast.error("Error favouriting preset")
    }
  }

  /**
   * When the "delete" trash icon is pressed, delete the current preset
   *
   * @param preset
   */
  const onDeletePresetClicked = async (preset) => {
    const confirmation = await window.confirm("Are you sure you want to delete this preset")
    if (confirmation) {
      try {
        const deleted = await deletePreset(preset)
        if (!deleted) {
          throw new Error("Unknown error")
        } else {
          toast.success("Preset deleted")
        }
      } catch (error) {
        console.error(error)
        toast.error("Error deleting preset")
      }
    }
  }

  /**
   * When the "export" button is clicked, export the currently loaded EQ settings
   * as a text file in Squiglink format
   *
   */
  const onExportClicked = async () => {
    const errors = validateEqSettings(dspSettings[mode])
    if (errors.length > 0) {
      console.debug(errors)
      toast.error("Error exporting current EQ settings")
      return
    }
    const textData = eqSettingsToText(dspSettings[mode])
    const fileName = activePreset
      ? `${slugify(activePreset.name, {
          lower: true,
          strict: true,
        })}-preset-export.txt`
      : "jds-preset-export.txt"
    const blob = new Blob([textData], { type: "text/plain" })
    downloadBlob(blob, "text/plain", fileName)
    toast.success("Current EQ settings exported")
  }

  /**
   * When the "export all" button is pressed, convert all currently available
   * presets and convert them to Squiglink-compatible text files. Then zip
   * them and prompt a download.
   *
   */
  const onExportAllClicked = async () => {
    const zip = new JSZip()

    presets
      .filter((preset) => preset.mode.toLowerCase() === mode.toLowerCase())
      .forEach((preset, i) => {
        const errors = validateEqSettings(preset.config)
        if (errors.length > 0) {
          toast.error(`Error adding preset '${preset.name}' to export`)
          return
        }
        const textData = eqSettingsToText(preset.config)
        const fileName = `jds-presets/${slugify(preset.name, {
          lower: true,
          strict: true,
        })}-preset-export-${i + 1}.txt`
        zip.file(fileName, textData)
      })

    const base64 = await zip.generateAsync({ type: "base64" })
    downloadBase64(base64, "application/zip", `jds-presets.zip`)
    toast.success("All presets exported")
  }

  /**
   * When either RCA or Headphone sidebar button is clicked, change the mode.
   *
   */
  const onModeChanged = async (mode) => {
    setMode(mode)
    setActivePreset(null)
  }

  /**
   * When the "Save as new preset" button is clicked in the header, open the overlay.
   *
   */
  const onSaveNewPresetClicked = async () => {
    openNewPresetModal()
  }

  /**
   * When the sidebar "Import Preset" button is clicked, open the overlay.
   *
   */
  const onImportPresetClicked = async () => {
    openImportPresetModal()
  }

  /**
   * When the "get link" button is clicked, encode the current EQ settings into
   * the URL and save it to the clipboard
   *
   */
  const onGetLinkClicked = async () => {
    const paramKey = "share"
    const paramValue = eqSettingsToURLParam(dspSettings[mode])
    const url = `${window.location.origin}/?${paramKey}=${encodeURIComponent(paramValue)}`
    navigator.clipboard.writeText(url)
    console.debug(`Saving url '${url}' to clipboard`)
    toast.success("Link saved to clipboard")
  }

  /**
   * When the "save" button is clicked in the header, update the current preset.
   *
   */
  const onConfirmNewPresetClicked = async (values) => {
    try {
      const newPreset = await createPreset({
        name: values.name,
        mode: mode.toLowerCase(),
        config: dspSettings[mode],
      })
      if (!newPreset) {
        throw new Error("Error creating preset")
      } else {
        closeNewPresetModal()
        toast.success("Created preset")
      }
    } catch (error) {
      console.error(error)
      toast.error("Error creating preset")
    }
  }

  /**
   * When the device is connected, load the settings
   *
   */
  useEffect(() => {
    if (device.status === DEVICE_CONNECTED) {
      ;(async () => {
        const deviceSettings = await device.getSettings()
        setDspSettings(deviceSettings["Configuration"]["DSP"])
        setDeviceSettings(deviceSettings)
      })()
    }
  }, [device.status])

  /**
   * When local settings change, send the changes to the device
   *
   */
  useEffect(() => {
    if (device.status === DEVICE_CONNECTED && dspSettings && deviceSettings) {
      // When the settings initially load from the device, we don't want to
      // immediately send them back to the device on page-load, so we use this
      if (deviceReady.current === true) {
        ;(async () => {
          const newDeviceSettings = {
            Configuration: { DSP: { [mode]: toSettingsUpdate(dspSettings[mode]) } },
          }
          try {
            let response
            if (autoSave) {
              response = await device.updateSettings(newDeviceSettings)
            } else if (eqChanged) {
              response = await device.auditionSettings(newDeviceSettings)
            }
            if (response) {
              toast.success("Device updated")
            }
          } catch (error) {
            console.error(error)
            toast.error("Error updating device")
          }
        })()
      } else {
        deviceReady.current = true
      }
    }
  }, [device.status, dspSettings, deviceSettings, autoSave])

  /**
   * Track when a preset has become outdated due to the user updating the eq
   * settings while a preset is loaded
   *
   */
  useEffect(() => {
    if (activePreset && dspSettings[mode]) {
      setActivePresetOutdated(
        JSON.stringify(dspSettings[mode]) !== JSON.stringify(activePreset.config)
      )
    }
  }, [dspSettings, activePreset])

  /**
   * Track if the page has loaded with a valid "share" URL parameter and load it
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const encodedShareParam = queryParams.get("share")
    if (encodedShareParam) {
      const decodedShareParam = decodeURIComponent(encodedShareParam)
      try {
        sharedEqSettings.current = urlParamToEqSettings(decodedShareParam)
        console.debug("Loading shared equaliser configuration:", sharedEqSettings.current)
        openShareModal()
      } catch (error) {
        console.error(error)
        toast.error("Error loading shared eq settings")
      }
    }
  }, [location.search])

  return (
    <>
      <EqualizerLayout
        header={
          <PresetHeader
            activePreset={activePreset}
            activePresetOutdated={activePresetOutdated}
            onDeletePresetClicked={onDeletePresetClicked}
            onExportClicked={onExportClicked}
            onFavouritePresetClicked={onFavouritePresetClicked}
            onSavePresetClicked={onSavePresetClicked}
            onSaveNewPresetClicked={onSaveNewPresetClicked}
            onGetLinkClicked={onGetLinkClicked}
            autoSave={autoSave}
            onAutoSaveChange={setAutoSave}
          />
        }
        content={
          <div className="relative h-full w-full">
            {eqChanged && !autoSave && device.status === DEVICE_CONNECTED && (
              <button
                onClick={() => {
                  onEqSave()
                }}
                className="absolute right-[5%] top-[12%] z-10 flex cursor-pointer items-center justify-center gap-1 rounded-lg bg-red px-3 py-2 text-xs font-bold text-white"
              >
                <span className="translate-y-px">Save to Device?</span>
                <InfoIcon className="h-3 w-auto text-white" />
              </button>
            )}
            <EqualizerCanvas eqSettings={dspSettings[mode]} mode={mode} onEqChange={onEqChange} />
          </div>
        }
        footer={
          <Tabs selectedKey={selectedTab} onSelectionChange={setSelectedTab}>
            <Tab key="tabularEQ" title="Tabular EQ">
              <EqualizerTable eqSettings={dspSettings[mode]} mode={mode} onEqChange={onEqChange} />
            </Tab>
            <Tab key="preampGain" title="Preamp Gain">
              <PreampGainSettings
                eqSettings={dspSettings[mode]}
                mode={mode}
                onEqChange={onEqChange}
              />
            </Tab>
          </Tabs>
        }
        sidebar={
          <EqualiserSidebar
            mode={mode}
            presets={presets}
            activePreset={activePreset}
            onModeChanged={onModeChanged}
            onPresetSeleceted={onPresetSelected}
            onImportPresetClicked={onImportPresetClicked}
            onExportAllClicked={onExportAllClicked}
            onDisableEqClicked={onDisableEqClicked}
          />
        }
      />
      <NewPresetModal
        isOpen={newPresetModalOpen}
        onClose={closeNewPresetModal}
        onConfirm={onConfirmNewPresetClicked}
      />
      <ImportPresetModal
        isOpen={importPresetModalOpen}
        onOpenChange={onImportPresetModalChange}
        onImportPresetConfirmed={onImportPresetConfirmed}
      />
      <ConfirmationModal
        question="Apply Shared Preset?"
        description={
          "A user has shared a preset with you. Would you like to apply it to your device?"
        }
        confirmText="Yes"
        isOpen={shareModalOpen}
        onClose={closeShareModal}
        onConfirm={onConfirmShareClicked}
      />
    </>
  )
}

export default EqualizerPage
