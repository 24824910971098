import { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import { ReactComponent as InputArrowUpIcon } from "@/icons/input-arrow-up-icon.svg"
import { ReactComponent as InputArrowDownIcon } from "@/icons/input-arrow-down-icon.svg"

const NumberInput = ({
  value = 0,
  step = 1,
  min,
  max,
  tabIndex,
  disabled,
  onDebouncedChange,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(parseFloat(value))
  const [isPressingKey, setPressingKey] = useState(false)
  const [isValid, setValid] = useState(true)

  const arrowUpRef = useRef()
  const arrowDownRef = useRef()

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleBlur = () => {
    if (validate()) {
      onDebouncedChange(parseFloat(inputValue))
    }
  }

  const roundToStep = (num) => {
    // Count decimal places in step
    const stepStr = step.toString()
    const decimals = stepStr.includes('.') ? stepStr.split('.')[1].length : 0
    // Round to the appropriate number of decimal places
    return Number(Math.round(num + 'e' + decimals) + 'e-' + decimals)
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      if (validate()) {
        onDebouncedChange(parseFloat(inputValue))
        e.target.blur()
      }
      return
    }

    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault()
      setPressingKey(true)
    }
    if (hasValidNumber()) {
      if (e.key === "ArrowUp") {
        arrowUpRef.current.classList.add("translate-x-px", "translate-y-px")
        const newValue = roundToStep(parseFloat(inputValue) + parseFloat(step))
        setInputValue(newValue)
      } else if (e.key === "ArrowDown") {
        arrowDownRef.current.classList.add("translate-x-px", "translate-y-px")
        const newValue = roundToStep(parseFloat(inputValue) - parseFloat(step))
        setInputValue(newValue)
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault()
      arrowUpRef.current.classList.remove("translate-x-px", "translate-y-px")
      arrowDownRef.current.classList.remove("translate-x-px", "translate-y-px")
      setPressingKey(false)
    }
  }

  const handleUpClick = () => {
    if (hasValidNumber()) {
      const newValue = roundToStep(parseFloat(inputValue) + parseFloat(step))
      setInputValue(newValue)
      onDebouncedChange(newValue)
      arrowUpRef.current.classList.add("translate-x-px", "translate-y-px")
      setTimeout(() => {
        arrowUpRef.current.classList.remove("translate-x-px", "translate-y-px")
      }, 100)
    }
  }

  const handleDownClick = () => {
    if (hasValidNumber()) {
      const newValue = roundToStep(parseFloat(inputValue) - parseFloat(step))
      setInputValue(newValue)
      onDebouncedChange(newValue)
      arrowDownRef.current.classList.add("translate-x-px", "translate-y-px")
      setTimeout(() => {
        arrowDownRef.current.classList.remove("translate-x-px", "translate-y-px")
      }, 100)
    }
  }

  const validate = () => {
    if (
      !hasValidNumber() ||
      (min !== undefined && parseFloat(inputValue) < min) ||
      (max !== undefined && parseFloat(inputValue) > max)
    ) {
      setValid(false)
      return false
    }
    setValid(true)
    return true
  }

  const hasValidNumber = () => {
    return inputValue !== "" && inputValue !== null && inputValue !== undefined
  }

  // Update local value when prop value changes
  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div className="type-body-base group relative flex items-center font-bold">
      <input
        type="number"
        className={cx(
          "h-10 w-20 rounded border pl-5 shadow-inside",
          "focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
          "disabled:pointer-events-none disabled:opacity-50",
          isValid
            ? "border-earth bg-mine-shaft group-focus-within:border-dark-blue"
            : "bg-brown border-darker-red"
        )}
        aria-roledescription="Number field"
        min={min}
        max={max}
        step={step}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        tabIndex={tabIndex}
        disabled={disabled}
        {...props}
      />
      <div className={cx("ml-2 flex flex-col space-y-px", { "pointer-events-none": disabled })}>
        <button
          className={cx("clickable h-[9px] w-[9px]", disabled ? "text-silver" : "text-blue")}
          onClick={handleUpClick}
          ref={arrowUpRef}
        >
          <InputArrowUpIcon />
        </button>
        <button
          className={cx(
            "clickable h-[9px] w-[9px]",
            disabled ? "text-dove-gray" : "text-dark-blue"
          )}
          onClick={handleDownClick}
          ref={arrowDownRef}
        >
          <InputArrowDownIcon />
        </button>
      </div>
    </div>
  )
}

NumberInput.propTypes = {
  value: PropTypes.number,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default NumberInput
