import "@/css/styles.css"

import { AuthProvider } from "@/contexts/auth"
import { SerialDeviceProvider } from "@/contexts/serial-device"
import { NextUIProvider } from "@nextui-org/react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import MobileWarningOverlay from "./components/MobileWarningOverlay"
import routes from "./routes"

const el = document.getElementById("root")

const root = ReactDOM.createRoot(el)
root.render(
  <NextUIProvider>
    <SerialDeviceProvider>
      <AuthProvider>
        <RouterProvider router={createBrowserRouter(routes)} />
      </AuthProvider>
    </SerialDeviceProvider>
    <ToastContainer position="bottom-left" theme="colored" autoClose={2000} />
    <MobileWarningOverlay />
  </NextUIProvider>
)
