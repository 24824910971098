import { useState, useEffect } from "react"

const CHECK_INTERVAL = 20 * 60 * 1000 // Check every 20 minutes
const INITIAL_DELAY = Math.random() * 60 * 1000 // Random delay up to 1 minute

export const useVersionCheck = () => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)
  const currentVersion = process.env.REACT_APP_GIT_COMMIT

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch(`/version.json?t=${Date.now()}`, {
          cache: "no-cache",
        })
        const { version } = await response.json()

        if (version && version !== currentVersion) {
          setNewVersionAvailable(true)
        }
      } catch (error) {
        console.error("Version check failed:", error)
      }
    }

    const initialCheck = setTimeout(checkVersion, INITIAL_DELAY)
    const interval = setInterval(checkVersion, CHECK_INTERVAL)

    return () => {
      clearTimeout(initialCheck)
      clearInterval(interval)
    }
  }, [currentVersion])

  return newVersionAvailable
}
