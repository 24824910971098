import { useState, useEffect } from "react"
import cx from "classnames"

import NumberInput from "@/components/forms/NumberInput"
import PowerToggle from "@/components/forms/PowerToggle"
import Label from "@/components/forms/Label"

const EqualizerTable = ({ eqSettings, onEqChange, mode }) => {
  const [bands, setBands] = useState([])

  /**
   * On settings change
   *
   * When parent settings change, update the bands
   */
  useEffect(() => {
    if (eqSettings) {
      setBands(Object.entries(eqSettings).filter(([key, value]) => key !== "Preamp"))
    }
  }, [eqSettings, mode])

  /**
   * Update
   *
   * @param {*} band
   * @param {*} domain
   * @param {*} value
   * @returns
   */
  const update = async (band, domain, value) => {
    const success = await onEqChange([[band[0], domain, value]])
    return success
  }

  return (
    <div className="min-w-300">
      {bands.length && (
        <div className={`grid grid-cols-${bands.length + 1} gap-x-5 gap-y-3`}>
          {bands.map((band, i) => {
            const domain = band[1]["Gain"]
            const value = parseFloat(domain["Current"])
            return (
              <Band
                key={`label-${i}`}
                name={band[0]}
                number={i + 1}
                isActive={value !== 0.0}
                disable={() => {
                  return update(band, "Gain", 0.0)
                }}
              />
            )
          })}
          <Label text="Gain" />
          {bands.map((band, i) => {
            const domain = band[1]["Gain"]
            const value = parseFloat(domain["Current"])
            const min = parseFloat(domain["Min"])
            const max = parseFloat(domain["Max"])
            return (
              <NumberInput
                key={`gain-${i}-${value}`}
                value={value}
                tabIndex={i * 3 + 1}
                min={min}
                max={max}
                step="0.1"
                onDebouncedChange={(value) => {
                  return update(band, "Gain", value)
                }}
              />
            )
          })}
          <Label text="Freq" />
          {bands.map((band, i) => {
            const domain = band[1]["Frequency"]
            const value = parseFloat(domain["Current"])
            const min = parseFloat(domain["Min"])
            const max = parseFloat(domain["Max"])
            return (
              <NumberInput
                key={`freq-${i}-${value}`}
                value={value}
                tabIndex={i * 3 + 2}
                min={min}
                max={max}
                onDebouncedChange={(value) => {
                  return update(band, "Frequency", value)
                }}
              />
            )
          })}
          <Label
            text="Q"
            info={
              <div className="max-w-md">
                Controls the width of the frequency range affected by the filter. A higher Q focuses
                on a narrower range, while a lower Q affects a wider range.
              </div>
            }
          />
          {bands.map((band, i) => {
            const domain = band[1]["Q"]
            const value = parseFloat(domain["Current"])
            const min = parseFloat(domain["Min"])
            const max = parseFloat(domain["Max"])
            return (
              <NumberInput
                key={`q-${i}-${value}`}
                value={value}
                tabIndex={i * 3 + 3}
                min={min}
                max={max}
                step="0.01"
                onDebouncedChange={(value) => {
                  return update(band, "Q", value)
                }}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const Band = ({ className = "", name, number, isActive, disable }) => {
  return (
    <div className={cx("pb-1", number === 1 ? "col-start-2" : "", className)}>
      <div className="type-body-xs font-medium">{name}</div>
      <div className="type-body-base mt-1 flex items-center font-bold">
        Band {number}
        <PowerToggle isActive={isActive} disable={disable} />
      </div>
    </div>
  )
}

export default EqualizerTable
